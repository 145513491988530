import { AuthContext, Context } from 'core/contexts/AuthContext';
import { useContext } from 'react';

export default function useAuthContext(): Context {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error("useAuthContext must be within AuthProvider")
  }

  return context
}