import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Router } from 'routing/Router';
import { CssBaseline } from '@mui/material';
import { AuthProvider } from 'core/contexts/AuthContext';
import { Config, DAppProvider, Ropsten } from '@usedapp/core';

const config: Config = {
  readOnlyChainId: Ropsten.chainId,
  readOnlyUrls: {
    [Ropsten.chainId]: window.__APP_CONFIG__.rpcUrl
  },
}

const Root: React.FC = () => {
  return (
    <React.Fragment>
      <CssBaseline />
      <DAppProvider config={config}>
        <BrowserRouter>
          <AuthProvider>
            <Router />
          </AuthProvider>
        </BrowserRouter>
      </DAppProvider>
    </React.Fragment >
  );
}

export default Root;
