import { useMessage } from "components/snackbar/useMessage";
import { useCallback, useEffect, useState } from "react";
import { Company, getCompanies, getShareholderShares, Serie, Share } from "services/shareholder";
import PageTemplate from "templates/PageTemplate";
import TableComponent from "../shareholderShares/table/Table";
import jwtDecode from "jwt-decode"
import { Box } from "@mui/material";
import SnackbarComponent from "components/snackbar/Snackbar";

export type TokenTypes = {
  shareholderId: string;
  firstName: string;
  lastName: string;
  pesel: string;
  chainAddress: string;
  exp: number;
  iat: number;
}

export type TableDataObj = {
  company: Company | undefined;
  serie: Serie;
  shares: Array<Share>;
}

const ShareholderShares: React.FC = () => {
  const [companies, setCompanies] = useState<Array<Company>>([]);
  const [series, setSeries] = useState<Array<Serie>>([]);
  const [shares, setShares] = useState<Array<Share>>([]);
  const [tableData, setTableData] = useState<Array<TableDataObj>>([])
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { setErrorMessage, message, clearMessage } = useMessage();

  const token = localStorage.getItem('token') || '{}';
  const { shareholderId } = jwtDecode<TokenTypes>(token);

  const getTableData = useCallback(
    (): Array<TableDataObj> => {

      const dataObjectsArr: Array<TableDataObj> = [];
      for (const serie of series) {
        const serieShares = shares.filter(share => share.sharesSerieId === serie.sharesSerieId);
        if (serieShares.length > 0) {
          const company = companies.find(company => company.companyId === serie.companyId);

          dataObjectsArr.push({
            company,
            serie,
            shares: serieShares
          });
        }

      }

      return dataObjectsArr;
    }, [companies, series, shares]
  )

  useEffect(() => {
    getCompanies()
      .then(res => {
        setCompanies(res.data.companies);
        setSeries(res.data.series);
      })
      .catch(err => {
        setErrorMessage('error.backend');
        console.error(err)
      })
  }, [setErrorMessage])

  useEffect(() => {
    getShareholderShares(shareholderId)
      .then(res => {
        setShares(res.data);
      })
      .catch(err => {
        setErrorMessage('error.backend')
      })
  }, [setErrorMessage, shareholderId])

  useEffect(() => {
    if (companies.length > 0 && series.length > 0 && shares.length > 0) {
      setIsLoading(false);
      setTableData(getTableData())
    }
  }, [companies, getTableData, series, shares])

  return (
    <PageTemplate isFullScreen>
      <Box sx={{ padding: "3rem 5rem 1rem", flexGrow: '1', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', overflow: 'auto' }}>
        <TableComponent data={tableData} isLoading={isLoading} />
      </Box>
      <SnackbarComponent message={message} onClose={clearMessage} />
    </PageTemplate>
  )
}

export default ShareholderShares;