import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import loginPl from 'pages/login/translation/pl.json'
import loginEn from 'pages/login/translation/en.json'
import NoMatchPl from "pages/404/translations/pl.json";
import NoMatchEn from "pages/404/translations/en.json";
import Web3ConnectPl from 'components/web3-connect/translations/pl.json';
import Web3ConnectEn from 'components/web3-connect/translations/en.json';
import ShareholderSharesEn from "pages/shareholderShares/translations/en.json";
import ShareholderSharesPl from "pages/shareholderShares/translations/pl.json";
import SerieSharesEn from 'pages/shares/translations/en.json';
import SerieSharesPl from 'pages/shares/translations/pl.json';
import SharesPurchaseBoardEn from "pages/sharesPurchaseBoard/translations/en.json";
import SharesPurchaseBoardPl from "pages/sharesPurchaseBoard/translations/pl.json";
import ShareholderPurchaseOffersEn from "pages/shareholderOffers/translations/en.json";
import ShareholderPurchaseOffersPl from "pages/shareholderOffers/translations/pl.json";
import SnackbarEn from "components/snackbar/translations/en.json";
import SnackbarPl from "components/snackbar/translations/pl.json";
import AddPurchaseModalEn from "components/add-purchase-modal/translations/en.json";
import AddPurchaseModalPl from "components/add-purchase-modal/translations/pl.json";
import AddSaleOfferModalEn from "components/add-sale-modal/translations/en.json";
import AddSaleOfferModalPl from "components/add-sale-modal/translations/pl.json";

const resources = {
  "en": {
    login: loginEn,
    noMatch: NoMatchEn,
    web3Connect: Web3ConnectEn,
    shareholderShares: ShareholderSharesEn,
    serieShares: SerieSharesEn,
    sharesPurchaseBoard: SharesPurchaseBoardEn,
    shareholderPurchaseOffers: ShareholderPurchaseOffersEn,
    snackbar: SnackbarEn,
    addPurchaseModal: AddPurchaseModalEn,
    addSaleModal: AddSaleOfferModalEn
  },
  "pl": {
    login: loginPl,
    noMatch: NoMatchPl,
    web3Connect: Web3ConnectPl,
    shareholderShares: ShareholderSharesPl,
    serieShares: SerieSharesPl,
    sharesPurchaseBoard: SharesPurchaseBoardPl,
    shareholderPurchaseOffers: ShareholderPurchaseOffersPl,
    snackbar: SnackbarPl,
    addPurchaseModal: AddPurchaseModalPl,
    addSaleModal: AddSaleOfferModalPl
  }
}

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'pl',
    resources,
    interpolation: {
      escapeValue: false
    },
  })

export default i18next;