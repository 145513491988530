import { providers, utils, Contract } from "ethers";
import lockingContractAbi from 'contracts/LockingContract.json';
import yourTokenContractAbi from 'contracts/YourToken.json'

const signer = (new providers.Web3Provider(window.ethereum)).getSigner();

const lockingContractInterface = new utils.Interface(lockingContractAbi);
const yourTokenContractInterface = new utils.Interface(yourTokenContractAbi);

export const lockingContract = new Contract(window.__APP_CONFIG__.lockingContractAddress, lockingContractInterface, signer);
export const yourTokenContract = new Contract(window.__APP_CONFIG__.yourTokenContractAddress, yourTokenContractInterface, signer);
