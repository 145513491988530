import { Box, Typography } from "@mui/material";
import Web3Connect from 'components/web3-connect/Web3Connect';

const Navbar: React.FC = () => {

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", padding: "0 2rem", width: "100%", boxShadow: '0 0 20px 15px rgb(71, 122, 255)', backgroundColor: 'rgb(20, 30, 56)' }}>

      <Box sx={{ display: "flex", alignItems: 'end' }}>
        <Typography sx={{ fontWeight: 'bold', color: 'white' }} variant="h1" component="h1">7BC </Typography>
        <Typography sx={{ margin: '0 0 1rem .5rem', color: 'white' }} variant="h5" component="h5">Dom Maklerski Bank 7bulls Sp. z.o.o</Typography>
      </Box>

      <Box sx={{ display: "flex", gap: 2, alignItems: 'end', margin: '1rem 0' }}>
        <Box sx={{ display: "flex", gap: 2, alignItems: 'end', margin: '1rem 0' }}>
          <Web3Connect />
        </Box>
      </Box>

    </Box>
  )
}

export default Navbar; 