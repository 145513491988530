import { useLocation, Navigate } from "react-router-dom";
import { useEffect } from "react";
import useAuthContext from "core/contexts/useAuthContext";
import { useEthers } from "@usedapp/core";
import { login } from "services/shareholder";

const Auth: React.FC = ({ children }) => {
  const location = useLocation();
  const { token, setJWTToken, resetJWTToken } = useAuthContext();
  const { account, chainId, deactivate } = useEthers();

  useEffect(() => {
    if (chainId === 3) {
      if (!token && account) {
        login({ chainAddress: account })
          .then(newToken => {
            setJWTToken(newToken.data);
          })
          .catch(err => {
            console.error(err)
          })
      }
    } else {
      resetJWTToken();
      deactivate();
    }
  }, [token, setJWTToken, resetJWTToken, account, chainId, deactivate]);

  useEffect(() => {

    if (window.ethereum) {
      const resetToken = () => {
        resetJWTToken();
      }
      window.ethereum.on('chainChanged', resetToken);
      window.ethereum.on('accountsChanged', resetToken);

      return () => {
        window.ethereum.removeListener('chainChanged', resetToken);
        window.ethereum.removeListener('accountsChanged', resetToken);
      };
    }
  }, [resetJWTToken]);

  if (account && !token) return null;

  return account && token ? <>{children}</> : <Navigate to="/login" state={{ from: location }} />;
}

export default Auth;