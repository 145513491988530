import { Button, Box, Grid } from '@mui/material';
import { useEthers } from '@usedapp/core'
import { useEffect, useState } from 'react';
import Web3Modal from './Web3Modal';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useAuthContext from 'core/contexts/useAuthContext';
// import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { useMessage } from 'components/snackbar/useMessage';

const Web3Connect: React.FC = () => {
  const { activateBrowserWallet, account, deactivate } = useEthers()
  const [open, setOpen] = useState<boolean>(false);
  const { t } = useTranslation('snackbar, web3Conect');
  const navigate = useNavigate();
  const { token, resetJWTToken } = useAuthContext();
  const { setErrorMessage } = useMessage();

  const handleConnectToWallet = async (value: string) => {
    switch (value) {
      case "WalletConnect":
        // const walletconnect = new WalletConnectConnector({
        //   rpc: { 1: window.__APP_CONFIG__.rpcUrl },
        //   qrcode: true
        // })
        // activate(walletconnect)
        //   .catch(err => console.error(err))
        // setOpen(false)
        break;
      case "Metamask":
        if (typeof window.ethereum === 'undefined') {
          setErrorMessage(t('snackbar:missedMetamask'))
        } else {
          activateBrowserWallet();
        }
        setOpen(false)
        break;
      default:
        break;
    }

  }

  useEffect(() => {
    if (account) {
      navigate('/')
    }

  }, [account, navigate])

  const handleLogout = async () => {
    deactivate();
    localStorage.removeItem('shouldConnectMetamask')
    resetJWTToken();
  }

  return (
    <Box>
      {!token || !account ?
        (<Grid item>
          <Button variant='contained' onClick={() => setOpen(true)}>
            {t('web3Connect:btn.web3Login')}
          </Button>
        </Grid>) :
        (<Grid item>
          <Button variant='contained' onClick={handleLogout}>{t('web3Connect:btn.web3Logout')}</Button>
        </Grid>)
      }

      <Web3Modal
        open={open}
        onClose={() => setOpen(false)}
        connectToWallet={handleConnectToWallet}
      />

    </Box>
  );
}
export default Web3Connect;