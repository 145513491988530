import Login from 'pages/login/index';
import ShareholderShares from 'pages/shareholderShares/index';
import SharesPurchaseBoard from 'pages/sharesPurchaseBoard/index';
import ShareholderOffers from 'pages/shareholderOffers/index';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import Shares from 'pages/shares';

export const routes = [
  {
    name: 'Login',
    element: <Login />,
    path: '/login',
    permission: 'guest',
    isMenu: false,
  },
  {
    name: 'Your shares',
    element: <ShareholderShares />,
    path: '/',
    icon: <DashboardIcon />,
    permission: 'user',
    isMenu: true,
  }, {
    name: 'Purchase board',
    element: <SharesPurchaseBoard />,
    path: '/shares-board',
    icon: <SupervisedUserCircleIcon />,
    permission: 'user',
    isMenu: true,
  },
  {
    name: 'Your Offers',
    element: <ShareholderOffers />,
    path: '/shareholder-offers',
    icon: <ContactSupportIcon />,
    permission: 'user',
    isMenu: true,
  },
  {
    name: "Shares",
    element: <Shares />,
    path: '/shares/:sharesSerieId',
    permission: 'user',
    isMenu: false,
  }
]