import { Box, Typography } from "@mui/material";
import PageTemplate from "templates/PageTemplate";
import Web3Connect from "components/web3-connect/Web3Connect";
import SnackbarComponent from "components/snackbar/Snackbar";
import { useEffect } from "react";
import { useEthers } from "@usedapp/core";
import { useMessage } from "components/snackbar/useMessage";
import { useTranslation } from "react-i18next";

const Login: React.FC = () => {
  const { chainId } = useEthers();
  const { setErrorMessage, message, clearMessage } = useMessage();
  const { t } = useTranslation('snackbar')

  useEffect(() => {
    if (chainId !== 3) {
      setErrorMessage('error.badNetwork')
    }
  }, [chainId, setErrorMessage, t])

  return (
    <PageTemplate isFullScreen>
      <Box sx={{ flexGrow: '1', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <Typography variant='h1' component='h1' >7BC DM</Typography>
        <Typography sx={{ margin: '0 0 2rem' }} variant='h5' component='p'>Please connect your web3 wallet in order to see your account balance.</Typography>
        <Web3Connect />
      </Box>
      <SnackbarComponent message={message} onClose={clearMessage} />
    </PageTemplate>
  );

}

export default Login;