import { useState, createContext, useCallback } from "react";

interface Props {
  children: React.ReactNode;
}

export interface Context {
  token: string;
  setJWTToken: (token: string) => void;
  resetJWTToken: () => void;
}

type ProviderValues = {
  setJWTToken: (token: string) => void;
  resetJWTToken: () => void;
  token: string;
}

export const AuthContext = createContext<Context | undefined>(undefined);

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const [token, setToken] = useState<string>(localStorage.getItem('token') || '');

  const setJWTToken = useCallback((token) => {
    localStorage.setItem('token', token);
    setToken(token);
  }, []);

  const resetJWTToken = useCallback(() => {
    localStorage.removeItem('token');
    setToken('')
  }, []);

  const values: ProviderValues = {
    token, setJWTToken, resetJWTToken
  }

  return (
    <AuthContext.Provider value={values}>
      {children}
    </AuthContext.Provider>
  );
}
