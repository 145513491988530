import { Box } from "@mui/material";
import React from "react";
import Menu from "components/menu";
import Navbar from "components/navbar";
import useAuthContext from "core/contexts/useAuthContext";
import { useEthers } from "@usedapp/core";

type PropTypes = {
  isFullScreen?: boolean;
}

const PageTemplate: React.FC<PropTypes> = ({ children, isFullScreen }) => {
  const { token } = useAuthContext();
  const { account } = useEthers();
  const height = isFullScreen ? '100vh' : 'auto';
  const margin = (token && account) ? "0 0 0 18rem" : "0";

  return (
    <>
      {(token && account) && <Menu />}
      <Box sx={{ margin: margin, height: height, display: 'flex', flexDirection: 'column' }}>
        <Navbar />
        {children}
      </Box>
    </>
  )
}

export default PageTemplate;