import { useCallback, useState } from "react";

export type Kind = 'error' | 'success';

export type Message = {
  kind: Kind;
  text: string;
}

type UseMessage = {
  message: Message | undefined;
  setErrorMessage: (text: string) => void;
  clearMessage: () => void;
  setSuccessMessage: (text: string) => void;
}

export const useMessage = (): UseMessage => {
  const [message, setMessage] = useState<Message>();

  const setSuccessMessage = useCallback((text: string) => {
    setMessage({ kind: 'success', text })
  }, [])

  const setErrorMessage = useCallback((text: string) => {
    setMessage({ kind: 'error', text })
  }, [])

  const clearMessage = useCallback(() => {
    setMessage(undefined)
  }, [])

  return { message, setErrorMessage, setSuccessMessage, clearMessage };
}