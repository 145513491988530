import { AxiosResponse } from 'axios';
import { axiosInstanceWithAuthorization, defaultAxiosInstance } from "./instances/axiosInstances"

type ChainAddressType = {
  chainAddress: string;
}

export interface Shareholder {
  shareholderId: string;
  active: boolean;
  chainAddress: string;
  createdAt: string;
  firstName: string;
  lastName: string;
  pesel: number;
}

export interface Company {
  companyId: string;
  companyName: string;
  krs: number;
}

export interface Serie {
  companyId: string;
  sharesSerieId: string;
  sharesSerieName: string;
  sharesQuantity: number;
}

export type Share = {
  shareId: string;
  sharesSerieId: string;
  shareNumber: number;
  shareholderId: string;
  status: string;
}

export type PurchaseOffer = {
  purchaseOfferId: string;
  creationTransactionHash: string;
  deletionTransactionHash: string;
  buyerId: string;
  minSharesQuantity: number;
  maxSharesQuantity: number;
  sharePrice: number;
  sharesSerieId: string;
  isPriceVisibility: boolean;
  active: boolean;
  isSuspended: boolean;
}

export type PostedSaleOffer = {
  sellerId: string;
  purchaseOfferId: string;
  sharesQuantity: number;
  sharePrice: number;
  sharesList: string;
}

export type SaleOffer = {
  saleOfferId: string;
  sellerId: string;
  purchaseOfferId: string;
  sharesQuantity: number;
  sharePrice: number;
  sharesList: string;
}

type GetCompanyRespone = {
  companies: Array<Company>,
  series: Array<Serie>
}

type PurchaseOfferRes = {
  purchaseOffer: PurchaseOffer;
  message: string;
}

export const login = (chainAddress: ChainAddressType): Promise<AxiosResponse<string>> => {
  return defaultAxiosInstance.post(`/shareholders/login`, chainAddress)
}

export const getShareholders = (): Promise<AxiosResponse<Shareholder[]>> => {
  return axiosInstanceWithAuthorization.get(`/shareholders`)
}

export const getCompanies = (): Promise<AxiosResponse<GetCompanyRespone>> => {
  return axiosInstanceWithAuthorization.get(`/companies`)
}

export const getShareholderShares = (shareholderId: string): Promise<AxiosResponse<Array<Share>>> => {
  return axiosInstanceWithAuthorization.get(`/shares/getShareholderShares`, { params: { shareholderId: shareholderId } })
}

export const getShareholderSerieShares = (sharesSerieId: string, shareholderId: string): Promise<AxiosResponse<Array<Share>>> => {
  return axiosInstanceWithAuthorization.get(`/shares/getShareholderSerieShares`, {
    params: {
      sharesSerieId: sharesSerieId,
      shareholderId: shareholderId
    }
  })
}

export const getPurchaseOffers = (): Promise<AxiosResponse<Array<PurchaseOffer>>> => {
  return axiosInstanceWithAuthorization.get('/offers/getPurchaseOffers')
}

export const getShareholderPurchaseOffers = (shareholderId: string): Promise<AxiosResponse<Array<PurchaseOffer>>> => {
  return axiosInstanceWithAuthorization.get('/offers/getShareholderPurchaseOffers', { params: { shareholderId: shareholderId } })
}

export const addPurchaseOffer = (purchaseOffer: PurchaseOffer): Promise<AxiosResponse<PurchaseOfferRes>> => {
  return axiosInstanceWithAuthorization.post('/offers/addPurchaseOffer', purchaseOffer)
}

export const deletePurchaseOffer = (id: string): Promise<AxiosResponse<string>> => {
  return axiosInstanceWithAuthorization.delete('/offers/deletePurchaseOffer', { params: { purchaseOfferId: id } })
}

export const getSaleOffers = (): Promise<AxiosResponse<Array<SaleOffer>>> => {
  return axiosInstanceWithAuthorization.get('/offers/getSaleOffers')
}

export const addSaleOffer = (saleOffer: PostedSaleOffer): Promise<AxiosResponse<string>> => {
  return axiosInstanceWithAuthorization.post('/offers/addSaleOffer', saleOffer)
}

export const suspendPurchaseOffer = (id: string, transactionHash: string): Promise<AxiosResponse<string>> => {
  return axiosInstanceWithAuthorization.post('/offers/suspendPurchaseOffer', null, { params: { purchaseOfferId: id, deletionTransactionHash: transactionHash } })
}