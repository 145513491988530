import { Routes, Route } from "react-router-dom";
import NoMatch from "pages/404";
import Auth from 'core/Auth';
import { routes } from 'routing/routes';

export const Router: React.FC = () => {

  return (
    <Routes>
      {routes.map((route, i) => {
        if (route.permission === 'guest') {
          return <Route path={route.path} element={route.element} key={i} />
        } else if (route.permission === 'user') {
          return <Route path={route.path} element={
            <Auth>
              {route.element}
            </Auth>
          }
            key={i} />
        } else return null;
      })}
      <Route path="*" element={<NoMatch isShareholder />} />
    </Routes>
  )
}

