import { Box, Button, Divider, Typography } from "@mui/material";
import { useMessage } from "components/snackbar/useMessage";
import { useCallback, useEffect, useState } from "react";
import { Company, getCompanies, getSaleOffers, getShareholderPurchaseOffers, PurchaseOffer, SaleOffer, Serie, suspendPurchaseOffer } from "services/shareholder";
import PageTemplate from "templates/PageTemplate";
import { TokenTypes } from "pages/shareholderShares";
import jwtDecode from "jwt-decode";
import Loader from "components/loader/Loader";
import { useTranslation } from "react-i18next";
import SnackbarComponent from "components/snackbar/Snackbar";
import { LoadingButton } from "@mui/lab";
import { lockingContract } from "contracts/contracts";

type PurchaseOfferWithLoading = PurchaseOffer & { isBtnLoading: boolean };

const ShareholderOffers: React.FC = () => {
  const [companies, setCompanies] = useState<Array<Company>>([]);
  const [series, setSeries] = useState<Array<Serie>>([]);
  const [purchaseOffers, setPurchaseOffers] = useState<Array<PurchaseOfferWithLoading>>([])
  const [saleOffers, setSaleOffers] = useState<Array<SaleOffer>>([]);
  const [isOffersLoading, setIsOffersLoading] = useState<boolean>(true)
  const { setErrorMessage, clearMessage, message } = useMessage();
  const { t } = useTranslation('shareholderPurchaseOffers')

  const token = localStorage.getItem('token') || '{}';
  const { shareholderId } = jwtDecode<TokenTypes>(token);

  const fetchShareholderPurchaseOffers = useCallback(
    () => {
      getShareholderPurchaseOffers(shareholderId)
        .then(res => {
          const purchaseOffersArr = res.data.filter(offer => offer.active === true);
          const purchaseOffersData = [];
          for (const offer of purchaseOffersArr) {
            purchaseOffersData.push({ ...offer, isBtnLoading: false })
          }
          setPurchaseOffers(purchaseOffersData);
        })
        .catch(err => {
          setErrorMessage('error.backend')
        })
    }, [setErrorMessage, shareholderId]
  )

  const fetchSaleOffers = useCallback(() => {
    getSaleOffers()
      .then(res => {
        setSaleOffers(res.data)
      })
      .catch(err => {
        setErrorMessage('error.backend')
      })
  }, [setErrorMessage])

  useEffect(() => {
    getCompanies()
      .then(res => {
        setCompanies(res.data.companies);
        setSeries(res.data.series);
      })
      .catch(err => {
        setErrorMessage('error.backend')
      })
  }, [setErrorMessage])

  useEffect(() => {
    fetchSaleOffers();
  }, [fetchSaleOffers, setErrorMessage])

  useEffect(() => {
    fetchShareholderPurchaseOffers();
  }, [fetchShareholderPurchaseOffers, setErrorMessage, shareholderId])

  useEffect(() => {
    if (companies.length > 0 && series.length > 0) {
      setIsOffersLoading(false);
    }
  }, [companies, purchaseOffers, series])

  const handleClickDeleteBtn = (purchaseOfferId: string, index: number) => {
    const purchaseOffersArr = [...purchaseOffers];
    purchaseOffersArr[index].isBtnLoading = true;
    setPurchaseOffers(purchaseOffersArr);

    lockingContract.deleteLockTokens(purchaseOfferId)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        suspendPurchaseOffer(purchaseOfferId, res.hash)
          .then(res => {
            const purchaseOffersArr = [...purchaseOffers];
            purchaseOffersArr[index].isBtnLoading = false;
            setPurchaseOffers(purchaseOffersArr);

            fetchShareholderPurchaseOffers();
            fetchSaleOffers();
          })
          .catch(err => {
            setErrorMessage('error.backend')
          })
      })
  }

  return (
    <PageTemplate>
      <Box sx={{ flexGrow: '1', padding: "3rem 5rem 1rem" }}>

        <Typography variant="h4" component="h4" align="center" sx={{ margin: '0 0 4rem' }}>{t('shareholderPurchaseOffers:purchaseOffersTitle')}</Typography>
        {isOffersLoading ? (
          <Loader />
        ) : (
          <>
            {purchaseOffers.map((offer, i) => {

              const serie = series.find(serie => serie.sharesSerieId === offer.sharesSerieId);
              const company = companies.find(company => serie?.companyId === company.companyId)

              const saleOffersOfPurchaseOffer = saleOffers.filter(saleOffer => offer.purchaseOfferId === saleOffer.purchaseOfferId);

              return (
                <Box key={i}>
                  <Divider sx={{ margin: '2rem 0' }} />
                  <Box sx={{ margin: '4rem 0', backgroundColor: offer.isSuspended ? 'rgba(0, 0, 0, .1)' : 'none', filter: offer.isSuspended ? 'grayscale(100%)' : 'none', pointerEvents: offer.isSuspended ? 'none' : 'auto' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', padding: "0 4rem" }}>
                      <Typography sx={{ margin: '0 1rem 0 0' }} component="h5" variant="h5">{company?.companyName}, {t('shareholderPurchaseOffers:serie')} {serie?.sharesSerieName}, {offer.minSharesQuantity}-{offer.maxSharesQuantity} {t('shareholderPurchaseOffers:shares')}, {offer.sharePrice} {t('shareholderPurchaseOffers:sharePrice')}</Typography>
                      <LoadingButton loading={offer.isBtnLoading} onClick={() => handleClickDeleteBtn(offer.purchaseOfferId, i)}>{t('shareholderPurchaseOffers:deleteBtn')}</LoadingButton>
                    </Box>

                    <Box sx={{ margin: '2rem 0 3rem' }} key={i}>
                      <Typography component="h5" variant="h5" >{t('shareholderPurchaseOffers:csOfferTitle')}:</Typography>

                      {saleOffersOfPurchaseOffer.map((offer, i) => {
                        return (
                          <Box key={i} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline', width: '100%', padding: "1rem 4rem", boxShadow: '0 0 10px 5px rgb(71, 122, 255)', margin: '2rem 0' }}>
                            <Typography sx={{ margin: '0 1rem 0 0' }} component="h6" variant="h6">{offer.sharesQuantity} {t('shareholderPurchaseOffers:shares')}, {offer.sharePrice} {t('shareholderPurchaseOffers:sharePrice')}</Typography>
                            <Box>
                              <Button variant="contained" sx={{ margin: '0 1rem 0 0' }}>{t('shareholderPurchaseOffers:confirmBtn')}</Button>
                              <Button variant="contained">{t('shareholderPurchaseOffers:csBtn')}</Button>
                            </Box>
                          </Box>
                        )
                      })}
                    </Box>
                  </Box>
                </Box>
              )
            })}
          </>
        )
        }
      </Box>
      <SnackbarComponent message={message} onClose={clearMessage} />
    </PageTemplate >
  )
};

export default ShareholderOffers;

