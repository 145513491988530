import { TableHead, TableRow, TableCell } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Header } from 'const/sharesPurchaseHead'

type Props = {
  def: Array<Header>;
}

const TableHeader: React.FC<Props> = ({ def }) => {
  const { t } = useTranslation('sharesPurchaseBoard')

  return (
    <TableHead>
      <TableRow sx={{ backgroundColor: 'rgb(20, 30, 56)' }}>

        {def.map((item, i) => {
          return (
            <TableCell sx={{ fontSize: "1.3rem", color: 'white' }} key={i}>{t(`sharesPurchaseBoard:${item.text}`)}</TableCell>
          )
        })}

      </TableRow >
    </TableHead >
  )
}

export default TableHeader;