import { useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { List, ListItem, ListItemIcon, ListItemText, Box } from "@mui/material";
import AppsIcon from '@mui/icons-material/Apps';
import ListIcon from '@mui/icons-material/List';
import { routes } from 'routing/routes';
import LanguageSelector from 'components/language-selector';

type MenuItem = {
  name: string;
  element: JSX.Element;
  path: string;
  permission: string;
  isMenu: boolean;
  icon?: JSX.Element;
}

const Menu: React.FC = () => {
  const location = useLocation();
  const [selectedItem, setSelectedItem] = useState<string>(location.pathname);
  const [isIconsMenu, setIconsMenu] = useState<boolean>(false);

  const renderMenuItem = (item: MenuItem) => {
    if (item.isMenu === true) {
      return (
        <ListItem
          button
          key={item.name}
          selected={selectedItem === item.path}
          onClick={() => setSelectedItem(item.path)}
          component={Link}
          to={item.path}
          sx={{
            ':hover': {
              backgroundColor: 'rgb(40, 98, 250)'
            }
          }}>
          {isIconsMenu ? (
            <ListItemIcon sx={{ margin: "0 auto", color: "white", padding: "2rem 0" }} >
              {item.icon}
            </ListItemIcon>
          ) : (
            <ListItemText disableTypography primary={item.name} sx={{ textAlign: 'center', color: "white", padding: "2rem 3rem" }} />
          )}
        </ListItem>
      )
    } else return null
  }

  return (
    <List disablePadding sx={{ zIndex: '1', position: "fixed", display: 'flex', flexDirection: 'column', height: '100vh', width: "18rem", backgroundColor: 'rgb(20, 30, 56)' }}>
      <ListItem button sx={{ padding: "3rem 0", display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} onClick={() => setIconsMenu(!isIconsMenu)}>
        {isIconsMenu ? (
          <AppsIcon sx={{ fontSize: '2.5rem', color: 'white' }} />
        ) : (
          <ListIcon sx={{ fontSize: '2.5rem', color: 'white' }} />
        )}
      </ListItem>
      {
        routes.map((route, index) => {
          return renderMenuItem(route)
        })
      }
      <Box sx={{ margin: 'auto auto 2rem' }}>
        <LanguageSelector />
      </Box>
    </List >
  )
}

export default Menu;