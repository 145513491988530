export type Header = {
  label: string;
  text: string;
}

export const sharesHead: Header[] = [
  {
    label: 'names',
    text: "sharesName",
  },
  {
    label: 'quantity',
    text: "sharesQuantity",
  }
]