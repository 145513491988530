import { Box } from "@mui/material";
import SnackbarComponent from "components/snackbar/Snackbar";
import { useMessage } from "components/snackbar/useMessage";
import TableComponent from "pages/sharesPurchaseBoard/table/Table";
import { useState, useEffect, useCallback } from "react";
import { Company, getCompanies, getPurchaseOffers, PurchaseOffer, Serie } from "services/shareholder";
import PageTemplate from "templates/PageTemplate";

export type TableDataObj = {
  company: Company | undefined;
  series: Array<Serie>;
  purchaseOffers: Array<PurchaseOffer>;
}

const SharesPurchaseBoard: React.FC = () => {
  const [companies, setCompanies] = useState<Array<Company>>([]);
  const [series, setSeries] = useState<Array<Serie>>([]);
  const [purchaseOffers, setPurchaseOffers] = useState<Array<PurchaseOffer>>([])
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { setErrorMessage, message, clearMessage } = useMessage();

  const getTableData = (): Array<TableDataObj> => {

    const dataObjectsArr = companies.map(company => {
      const seriesArr = series.filter(serie => serie.companyId === company.companyId);

      const companyPurchaseOffers = purchaseOffers.filter(purchaseOffer => {
        const seriesPurchaseOffers = seriesArr.filter(serie => serie.sharesSerieId === purchaseOffer.sharesSerieId);
        if (seriesPurchaseOffers.length > 0) {
          return true
        } else return false;
      })

      return {
        company,
        series: seriesArr,
        purchaseOffers: companyPurchaseOffers
      }
    })
    return dataObjectsArr;
  }

  const fetchCompanies = useCallback(() => {
    getCompanies()
      .then(res => {
        setCompanies(res.data.companies);
        setSeries(res.data.series);
      })
      .catch(err => {
        setErrorMessage('error.backend')
      })
  }, [setErrorMessage])

  const fetchPurchaseOffers = useCallback(() => {
    getPurchaseOffers()
      .then(res => {
        setPurchaseOffers(res.data);
      })
      .catch(err => {
        setErrorMessage('error.backend')
      })
  }, [setErrorMessage])

  useEffect(() => {
    fetchCompanies();
    fetchPurchaseOffers();
  }, [fetchCompanies, fetchPurchaseOffers])

  useEffect(() => {
    if (companies.length > 0 && series.length > 0) {
      setIsLoading(false)
    }
  }, [companies.length, purchaseOffers.length, series.length])

  return (
    <PageTemplate isFullScreen>
      <Box sx={{ flexGrow: '1', padding: "3rem 5rem 1rem", display: 'flex', flexDirection: 'column', justifyContent: 'space-between', overflow: 'auto' }}>
        <TableComponent data={getTableData()} onAddPurchaseOffer={() => {
          fetchCompanies();
          fetchPurchaseOffers()
        }} isLoading={isLoading} />
      </Box>
      <SnackbarComponent message={message} onClose={clearMessage} />
    </PageTemplate>
  )
};

export default SharesPurchaseBoard;