import { Box, Button, DialogContent, DialogTitle, Divider, TextField, Typography } from "@mui/material";
import ActionModal from "components/modals/ActionModal";
import SnackbarComponent from "components/snackbar/Snackbar";
import { useMessage } from "components/snackbar/useMessage";
import { useFormik, FormikHelpers } from "formik";
import jwtDecode from "jwt-decode";
import { TokenTypes } from "pages/shareholderShares";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { addSaleOffer, getShareholderSerieShares, PostedSaleOffer, PurchaseOffer, Share } from "services/shareholder";
// import * as Yup from 'yup';

type PropTypes = {
  onSuccess: () => void;
  onClose: () => void;
  onError: () => void;
  offer: PurchaseOffer;
}

type SharesRange = {
  shareNumberStart: number;
  shareNumberEnd: number;
  sellQuantity: number;
  newSellQuantity: string;
  isDisabled: boolean;
  error: string | undefined;
}

const AddSaleModal: React.FC<PropTypes> = ({ onSuccess, onClose, onError, offer }) => {
  const { t } = useTranslation('serieShares, addSaleModal');
  const { setErrorMessage, message, clearMessage } = useMessage();
  const [sharesRanges, setSharesRanges] = useState<SharesRange[]>([])
  const [isSharesQuantityDisaled, setIsSharesQuantityDisabled] = useState<boolean>(true);
  const [isSharePriceDisaled, setIsSharePriceDisabled] = useState<boolean>(true);
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState<boolean>(false);

  const token = localStorage.getItem('token') || '{}';
  const { shareholderId } = jwtDecode<TokenTypes>(token);

  const getActiveSharesRanges = useCallback(
    (shares: Share[], minSharesQuantity: number) => {

      const activeSharesArr = shares?.filter(s => s.status === 'active' && s.sharesSerieId === offer.sharesSerieId)

      const sortedShares = activeSharesArr.sort((a, b) => Number(a.shareNumber) - Number(b.shareNumber));

      const sharesArr = []

      let sharesRange: SharesRange | undefined = undefined;

      for (const share of sortedShares) {

        if (sharesRange !== undefined && share.shareNumber - 1 === sharesRange.shareNumberEnd) {
          sharesRange.shareNumberEnd++;
        } else {
          sharesRange = {
            shareNumberStart: share.shareNumber,
            shareNumberEnd: share.shareNumber,
            sellQuantity: 0,
            newSellQuantity: '0',
            isDisabled: true,
            error: undefined
          };

          sharesArr.push(sharesRange)
        }
      }

      for (const item of sharesArr) {
        const sharesQuantity = item.shareNumberEnd - item.shareNumberStart + 1;

        if (sharesQuantity >= minSharesQuantity) {
          item.sellQuantity = minSharesQuantity
          minSharesQuantity = 0;
        } else {
          item.sellQuantity = sharesQuantity;
          minSharesQuantity -= sharesQuantity;
        }
      }

      return sharesArr;
    }, [offer.sharesSerieId]
  )

  useEffect(() => {
    getShareholderSerieShares(offer.sharesSerieId || '', shareholderId)
      .then(res => {
        setSharesRanges(getActiveSharesRanges(res.data, offer.minSharesQuantity));
      })
      .catch((err) => {
        console.error(err);
        setErrorMessage('error.backend')
      })
  }, [getActiveSharesRanges, offer.minSharesQuantity, offer.sharesSerieId, setErrorMessage, shareholderId])

  useEffect(() => {
    let areRangesInputsDisabled = true;

    for (const shares of sharesRanges) {
      if (shares.isDisabled === false) {
        areRangesInputsDisabled = false;
      }
    }

    if (!isSharePriceDisaled || !isSharesQuantityDisaled || !areRangesInputsDisabled) {
      setIsSubmitBtnDisabled(true);
    } else {
      setIsSubmitBtnDisabled(false)
    }
  }, [isSharePriceDisaled, isSharesQuantityDisaled, sharesRanges])

  const handleClickChangeBtn = (item: SharesRange) => {

    const sharesRangesArr = [...sharesRanges];
    const index = sharesRangesArr.findIndex(share => share.shareNumberStart === item.shareNumberStart);
    sharesRangesArr[index].isDisabled = !sharesRangesArr[index].isDisabled;
    sharesRangesArr[index].newSellQuantity = String(sharesRangesArr[index].sellQuantity);
    setSharesRanges(sharesRangesArr);

  }

  const handleClickConfirmSharesQuantity = (sharesQuantity: number) => {

    let totalShares = 0;
    for (const share of sharesRanges) {
      totalShares += share.shareNumberEnd - share.shareNumberStart + 1;
    }

    if (totalShares < sharesQuantity) {
      setErrorMessage('error.notEnoughShares')
    } else {
      const sharesRangesArr = [...sharesRanges];

      for (const item of sharesRangesArr) {
        if (sharesQuantity > 0 && item.sellQuantity < sharesQuantity) {

          const freeShares = item.shareNumberEnd - item.shareNumberStart + 1 - item.sellQuantity;

          if (freeShares === 0) {
            sharesQuantity -= item.sellQuantity;
          } else if (freeShares > 0 && freeShares + item.sellQuantity >= sharesQuantity) {
            item.sellQuantity = sharesQuantity;
            sharesQuantity = 0;
          } else if (freeShares > 0 && freeShares + item.sellQuantity < sharesQuantity) {
            item.sellQuantity += freeShares;
            sharesQuantity -= item.sellQuantity
          }
        } else {
          item.sellQuantity = sharesQuantity;
          sharesQuantity = 0;
        }
      }

      setSharesRanges(sharesRangesArr)
      setIsSharesQuantityDisabled(true)
    }

  }

  const renderHeader = () => {
    return (
      <>
        <DialogTitle>
          Offer
        </DialogTitle>
        <Divider />
      </>
    )
  }

  const initialValues: PostedSaleOffer = {
    sellerId: shareholderId,
    purchaseOfferId: offer.purchaseOfferId,
    sharesQuantity: offer.minSharesQuantity,
    sharePrice: offer.sharePrice,
    sharesList: '',
  }

  // const SaleOfferSchema = Yup.object().shape({
  //   mixSharesQuantity: Yup.number()
  //     .required('Only numbers.'),
  //   maxSharesQuantity: Yup.number()
  //     .required('Only numbers.'),
  //   sharePrice: Yup.number()
  //     .required('Only numbers.'),
  // })

  const formik = useFormik({
    initialValues: initialValues,
    // validationSchema: SaleOfferSchema,
    onSubmit: (values: PostedSaleOffer, { setSubmitting }: FormikHelpers<PostedSaleOffer>) => {
      for (const share of sharesRanges) {
        if (share.sellQuantity > 0) {
          values.sharesList = values.sharesList + `${share.shareNumberStart}-${share.shareNumberStart + share.sellQuantity};`
        }
      }
      addSaleOffer(values)
        .then((res) => {
          onSuccess();
        })
        .catch(err => {
          onError();
        })
        .finally(() => {
          setSubmitting(false);
        })
    }
  })

  const handleSharesRangeSellQuantityChange = (index: number, value: string) => {

    const sharesRangesArr = [...sharesRanges];

    sharesRangesArr[index] = {
      ...sharesRangesArr[index],
      newSellQuantity: value
    }

    setSharesRanges(sharesRangesArr);

  }

  const handleSharesRangeSellQuantityConfirm = (index: number) => {

    const sharesRangesArr = [...sharesRanges];

    if (Number(sharesRangesArr[index].newSellQuantity) > sharesRangesArr[index].shareNumberEnd - sharesRangesArr[index].shareNumberStart + 1) {
      sharesRangesArr[index] = {
        ...sharesRangesArr[index],
        error: 'To many shares'
      }
    } else {

      if (Number(sharesRangesArr[index].newSellQuantity) > sharesRangesArr[index].sellQuantity) {
        let differenceQuantity = Number(sharesRangesArr[index].newSellQuantity) - sharesRangesArr[index].sellQuantity;
        const indexOfSharesRangeItem = sharesRangesArr[index].shareNumberStart;
        sharesRangesArr.reverse();

        for (const shareRange of sharesRangesArr) {
          if (shareRange.shareNumberStart === indexOfSharesRangeItem) {
            continue;
          } else {
            if (differenceQuantity > 0) {
              if (shareRange.sellQuantity >= differenceQuantity) {
                shareRange.sellQuantity -= differenceQuantity;
                differenceQuantity = 0;
              } else {
                differenceQuantity -= shareRange.sellQuantity;
                shareRange.sellQuantity = 0;
              }
            }
          }
        }
        sharesRangesArr.reverse();
      } else {
        let differenceQuantity = sharesRangesArr[index].sellQuantity - Number(sharesRangesArr[index].newSellQuantity);
        const indexOfSharesRangeItem = sharesRangesArr[index].shareNumberStart;

        for (const shareRange of sharesRangesArr) {
          if (shareRange.shareNumberStart === indexOfSharesRangeItem) {
            continue;
          } else {
            if (differenceQuantity > 0) {
              const freeShares = shareRange.shareNumberEnd - shareRange.shareNumberStart + 1 - shareRange.sellQuantity;

              if (freeShares > 0 && freeShares >= differenceQuantity) {
                shareRange.sellQuantity += differenceQuantity;
                differenceQuantity = 0;
              } else if (freeShares > 0 && freeShares < differenceQuantity) {
                shareRange.sellQuantity += freeShares;
                differenceQuantity -= freeShares;
              }
            }
          }
        }
      }
      sharesRangesArr[index] = {
        ...sharesRangesArr[index],
        sellQuantity: Number(sharesRangesArr[index].newSellQuantity),
        newSellQuantity: '0',
        isDisabled: true,
        error: undefined
      }
    }

    setSharesRanges(sharesRangesArr);

  }

  const renderContent = () => {
    return (
      <>
        <DialogContent>

          <Typography>Shareholder</Typography>
          <Typography>Min. {offer.minSharesQuantity} {t('addSaleModal:shares')}, max {offer.maxSharesQuantity} {t('addSaleModal:shares')}, {offer.sharePrice} {t('addSaleModal:sharePrice')}</Typography>

          <Typography variant="h5" component="h5" sx={{ margin: '2rem 0 1rem' }}>{t('addSaleModal:answerHeader')}</Typography>

          <Box component='form' sx={{ display: 'flex', flexDirection: 'column' }} >

            {
              sharesRanges.map((item, i) => {
                return (
                  <Box key={i} sx={{ display: "flex", alignItems: "baseline", justifyContent: "space-between" }}>
                    <Typography variant="body1">{t('serieShares:sharesNumbers')}: {item.shareNumberStart} - {item.shareNumberEnd}</Typography>
                    <Box sx={{ display: "flex", alignItems: "baseline" }}>
                      <TextField
                        type="number"
                        size="small"
                        name={`${item.shareNumberStart}-${item.shareNumberEnd}`}
                        disabled={item.isDisabled}
                        onChange={(event) => handleSharesRangeSellQuantityChange(i, event.target.value)}
                        variant="outlined"
                        error={item.error ? true : false}
                        helperText={item.error}
                        value={item.isDisabled ? item.sellQuantity : item.newSellQuantity}
                        sx={{ width: '8rem' }} />
                      {item.isDisabled ? (
                        <Button sx={{ margin: "0 0 0 1rem" }} onClick={() => handleClickChangeBtn(item)}>{t('addSaleModal:changeBtn')}</Button>
                      ) : (
                        <Button sx={{ margin: "0 0 0 1rem" }} onClick={() => handleSharesRangeSellQuantityConfirm(i)}>{t('addSaleModal:confirmBtn')}</Button>

                      )}
                    </Box>
                  </Box>
                )
              })
            }

            <Box sx={{ display: "flex", alignItems: "baseline", margin: "4rem 0 .5rem" }}>
              <Typography variant="body1" sx={{ margin: '0 1rem 0 0' }}>{t('addSaleModal:quantity')}:</Typography>
              <TextField
                data-testid="dashboard.textInputSharesQuantity"
                error={formik.errors.sharesQuantity ? true : false}
                type="number"
                size="small"
                name="sharesQuantity"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.sharesQuantity}
                disabled={isSharesQuantityDisaled}
                helperText={
                  formik.errors.sharesQuantity && formik.touched.sharesQuantity && formik.errors.sharesQuantity
                }
                variant="outlined" />
              {isSharesQuantityDisaled ? (
                <Button onClick={() => setIsSharesQuantityDisabled(false)}>{t('addSaleModal:changeBtn')}</Button>
              ) : (
                <Button onClick={() => handleClickConfirmSharesQuantity(formik.values.sharesQuantity)} >Confirm</Button>

              )}
            </Box>
            <Box sx={{ display: "flex", alignItems: "baseline", margin: ".5rem 0" }}>
              <Typography variant="body1" sx={{ margin: '0 1rem 0 0' }}>{t('addSaleModal:sharePriceInput')}:</Typography>
              <TextField
                data-testid="dashboard.textInputSharePrice"
                error={formik.errors.sharePrice ? true : false}
                type="number"
                size="small"
                name="sharePrice"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.sharePrice}
                disabled={isSharePriceDisaled}
                helperText={
                  formik.errors.sharePrice && formik.touched.sharePrice && formik.errors.sharePrice
                }
                variant="outlined" />
              {isSharePriceDisaled ? (
                <Button onClick={() => setIsSharePriceDisabled(false)}>{t('addSaleModal:changeBtn')}</Button>
              ) : (
                <Button onClick={() => setIsSharePriceDisabled(true)}>{t('addSaleModal:confirmBtn')}</Button>
              )}
            </Box>
            <Box sx={{ display: "flex", alignItems: "baseline", margin: ".5rem 0" }}>
              <Typography variant="body1" sx={{ margin: '0 1rem 0 0' }}>{t('addSaleModal:totalPrice')}:</Typography>
              <Typography variant="body1" sx={{ margin: '0 1rem 0 0' }}>{formik.values.sharePrice * formik.values.sharesQuantity} 7BCPLN</Typography>
            </Box>

          </Box>

        </DialogContent>
        <Divider />

        <SnackbarComponent message={message} onClose={clearMessage} />

      </>
    )
  }

  return (
    <ActionModal
      submitBtnLabel={t('addSaleModal:submitBtn')}
      isSubmitBtnDisabled={isSubmitBtnDisabled}
      loading={formik.isSubmitting}
      header={renderHeader()}
      content={renderContent()}
      onSubmit={() => {
        formik.handleSubmit();
      }}
      onClose={onClose}
    />
  )
}

export default AddSaleModal;