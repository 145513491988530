export type Header = {
  label: string;
  text: string;
}

export const SharesPurchaseBoardHead: Header[] = [
  {
    label: 'names',
    text: "companyName",
  },
  {
    label: 'quantity',
    text: "offersQuantity",
  },
  {
    label: 'action',
    text: ""
  }
]