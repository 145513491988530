import { Box, Checkbox, DialogContent, DialogTitle, FormControlLabel, FormHelperText, MenuItem, Select, TextField, Typography } from "@mui/material";
import ActionModal from "components/modals/ActionModal";
import { useFormik, FormikHelpers } from "formik";
import jwtDecode from "jwt-decode";
import { TokenTypes } from "pages/shareholderShares";
import { useTranslation } from "react-i18next";
import { addPurchaseOffer, PurchaseOffer, Serie } from "services/shareholder";
import * as Yup from 'yup';
import { v4 } from 'uuid'
import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useEthers } from "@usedapp/core";
import { formatEther } from "ethers/lib/utils";
import { lockingContract, yourTokenContract } from "contracts/contracts";

type PropTypes = {
  onSuccess: () => void;
  onClose: () => void;
  onError: (errorMessage: string) => void;
  series: Array<Serie>;
}

const AddPurchaseModal: React.FC<PropTypes> = ({ onSuccess, onClose, onError, series }) => {
  const { t } = useTranslation("addPurchaseModal, snackbar");
  const [isApproveLoading, setIsApproveLoading] = useState<boolean>(false)
  const [allowance, setAllowance] = useState<number>(1)
  const { account } = useEthers();

  const token = localStorage.getItem('token') || '{}';
  const { shareholderId } = jwtDecode<TokenTypes>(token);

  useEffect(() => {
    yourTokenContract.allowance(account, window.__APP_CONFIG__.lockingContractAddress)
      .then((res: number) => {
        setAllowance(Number(formatEther(res)))
        setIsApproveLoading(false);
      })
      .catch((err: unknown) => {
        setAllowance(0);
        console.error(err)
      })
  }, [account])

  const handleApproveClick = async () => {

    await yourTokenContract.approve(window.__APP_CONFIG__.lockingContractAddress, '115792089237316195423570985008687907853269984665640564039457584007913129639935');
    setIsApproveLoading(true);

  }

  const renderHeader = () => {
    return (
      <DialogTitle>
        {t('addPurchaseModal:headerTitle')}
      </DialogTitle>
    )
  }

  const emptyInitialValues: PurchaseOffer = {
    purchaseOfferId: v4(),
    creationTransactionHash: '',
    deletionTransactionHash: '',
    sharesSerieId: '',
    buyerId: shareholderId,
    minSharesQuantity: 0,
    maxSharesQuantity: 0,
    isPriceVisibility: true,
    sharePrice: 0,
    active: false,
    isSuspended: false
  }

  const PurchaseOfferSchema = Yup.object().shape({
    sharesSerieId: Yup.string()
      .required('Required.'),
    minSharesQuantity: Yup.number()
      .moreThan(0, 'Must be greater than 0')
      .required('Required.'),
    maxSharesQuantity: Yup.number()
      .moreThan(0, 'Must be greater than 0')
      .required('Required.'),
    sharePrice: Yup.number()
      .moreThan(0, 'Must be greater than 0')
      .required('Required.'),
  })

  const formik = useFormik({
    initialValues: emptyInitialValues,
    validationSchema: PurchaseOfferSchema,
    onSubmit: async (values: PurchaseOffer, { setSubmitting }: FormikHelpers<PurchaseOffer>) => {

      lockingContract.lockTokens(values.purchaseOfferId, `${formik.values.maxSharesQuantity * formik.values.sharePrice}000000000000000000`)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((res: any) => {
          values.creationTransactionHash = res.hash;
          addPurchaseOffer(values)
            .then((res) => {
              onSuccess();
              setSubmitting(false);
            })
            .catch(err => {
              setSubmitting(false);
              console.error(err)
            })
        })

    }
  })

  const renderContent = () => {
    return (
      <DialogContent>

        <Box component='form' sx={{ display: 'flex', flexDirection: 'column' }} >

          <Box sx={{ display: "flex", alignItems: "baseline", margin: ".5rem 0" }}>
            <Typography variant="body1" sx={{ margin: '0 1rem 0 0' }}>{t('addPurchaseModal:sharesKind')}:</Typography>
            <Select
              name="sharesSerieId"
              value={formik.values.sharesSerieId}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              {series.map((serie, i) => {
                return (
                  <MenuItem key={i} value={serie.sharesSerieId}>{t('addPurchaseModal:serie')} {serie.sharesSerieName}</MenuItem>
                )
              })}
            </Select>
            <FormHelperText error>{formik.errors.sharesSerieId && formik.touched.sharesSerieId && formik.errors.sharesSerieId}</FormHelperText>
          </Box>
          <Box sx={{ display: "flex", alignItems: "baseline", margin: ".5rem 0" }}>
            <Typography variant="body1" sx={{ margin: '0 1rem 0 0' }}>{t('addPurchaseModal:minSharesQuantity')}:</Typography>
            <TextField
              data-testid="dashboard.textInputMinSharesQuantity"
              error={formik.errors.minSharesQuantity ? true : false}
              type="number"
              name="minSharesQuantity"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.minSharesQuantity}
              helperText={
                formik.errors.minSharesQuantity && formik.touched.minSharesQuantity && formik.errors.minSharesQuantity
              }
              variant="outlined" />
          </Box>
          <Box sx={{ display: "flex", alignItems: "baseline", margin: ".5rem 0" }}>
            <Typography variant="body1" sx={{ margin: '0 1rem 0 0' }}>{t('addPurchaseModal:maxSharesQuantity')}:</Typography>
            <TextField
              data-testid="dashboard.textInputMaxSharesQuantity"
              error={formik.errors.maxSharesQuantity ? true : false}
              type="number"
              name="maxSharesQuantity"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.maxSharesQuantity}
              helperText={
                formik.errors.maxSharesQuantity && formik.touched.maxSharesQuantity && formik.errors.maxSharesQuantity
              }
              variant="outlined" />
          </Box>
          <Box sx={{ display: "flex", alignItems: "baseline", margin: ".5rem 0" }}>
            <FormControlLabel control={<Checkbox checked={formik.values.isPriceVisibility} />} name="isPriceVisibility" onChange={formik.handleChange} label={`${t('addPurchaseModal:priceVisibility')}`} />
          </Box>
          <Box sx={{ display: "flex", alignItems: "baseline", margin: ".5rem 0" }}>
            <Typography variant="body1" sx={{ margin: '0 1rem 0 0' }}>{t('addPurchaseModal:sharePrice')}:</Typography>
            <TextField
              data-testid="dashboard.textInputSharePrice"
              error={formik.errors.sharePrice ? true : false}
              type="number"
              name="sharePrice"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.sharePrice}
              helperText={
                formik.errors.sharePrice && formik.touched.sharePrice && formik.errors.sharePrice
              }
              variant="outlined" />
          </Box>
          <Box sx={{ display: "flex", alignItems: "baseline", margin: ".5rem 0" }}>
            <Typography variant="body1" sx={{ margin: '0 1rem 0 0' }}>{t('addPurchaseModal:totalPrice')}: {formik.values.maxSharesQuantity * formik.values.sharePrice} 7BCPLN</Typography>
          </Box>

        </Box>

        {allowance ? null : <LoadingButton loading={isApproveLoading} variant="contained" fullWidth={true} onClick={handleApproveClick}>{t('addPurchaseModal:allowanceBtn')}</LoadingButton>}

      </DialogContent>
    )
  }

  return (
    <ActionModal
      isSubmitBtnDisabled={allowance ? false : true}
      submitBtnLabel={t('addPurchaseModal:submitBtn')}
      loading={formik.isSubmitting}
      header={renderHeader()}
      content={renderContent()}
      onSubmit={() => {
        formik.handleSubmit();
      }}
      onClose={onClose}
    />
  )
}

export default AddPurchaseModal;