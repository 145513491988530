import { Box, CircularProgress } from "@mui/material";

const Loader: React.FC = () => {

  return (
    <Box sx={{ width: '100%', textAlign: 'center', margin: '2rem 0' }}>
      <CircularProgress size='4rem' />
    </Box>
  )
}

export default Loader;