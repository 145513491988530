import { Box, Button, Divider, Typography } from "@mui/material";
import Loader from "components/loader/Loader";
import SaleModal from "components/add-sale-modal";
import SnackbarComponent from "components/snackbar/Snackbar";
import { useMessage } from "components/snackbar/useMessage";
import jwtDecode from "jwt-decode";
import { TokenTypes } from "pages/shareholderShares";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Company, getCompanies, getPurchaseOffers, getShareholders, getShareholderSerieShares, PurchaseOffer, Serie, Share, Shareholder } from "services/shareholder";
import PageTemplate from "templates/PageTemplate";

type SharesRange = {
  shareNumberStart: number;
  shareNumberEnd: number;
}

export type DataObj = {
  companyName: string | undefined;
  serieName: string | undefined;
  purchaseOffers: PurchaseOffer[];
  activeShares: SharesRange[];
  inactiveShares: SharesRange[];
}

const Shares: React.FC = () => {
  const [companies, setCompanies] = useState<Array<Company>>([]);
  const [series, setSeries] = useState<Array<Serie>>([]);
  const [shares, setShares] = useState<Array<Share>>([]);
  const [allPurchaseOffers, setAllPurchaseOffers] = useState<Array<PurchaseOffer>>([]);
  const [shareholders, setShareholders] = useState<Array<Shareholder>>([]);
  const [chosenPurchaseOffer, setChosenPurchaseOffer] = useState<PurchaseOffer>()
  const [data, setData] = useState<DataObj>();
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { sharesSerieId } = useParams();
  const { setErrorMessage, setSuccessMessage, message, clearMessage } = useMessage();
  const { t } = useTranslation('serieShares')

  const token = localStorage.getItem('token') || '{}';
  const { shareholderId } = jwtDecode<TokenTypes>(token);

  const serieId = sharesSerieId ? sharesSerieId : '';

  const getData = useCallback(
    (): DataObj => {

      const serie = series.find(serie => serie.sharesSerieId === serieId);

      const company = companies.find(company => company.companyId === serie?.companyId);

      const activeSharesArr = shares?.filter(s => s.status === 'active' && s.sharesSerieId === serieId)
      const inactiveSharesArr = shares?.filter(s => s.status === 'inactive' && s.sharesSerieId === serieId)

      const getSharesArr = (arr: Share[]) => {
        const sortedShares = arr.sort((a, b) => Number(a.shareNumber) - Number(b.shareNumber));

        const sharesArr = []

        let sharesRange: SharesRange | undefined = undefined;

        for (const share of sortedShares) {

          if (sharesRange !== undefined && share.shareNumber - 1 === sharesRange.shareNumberEnd) {
            sharesRange.shareNumberEnd++;
          } else {
            sharesRange = {
              shareNumberStart: share.shareNumber,
              shareNumberEnd: share.shareNumber
            };

            sharesArr.push(sharesRange)
          }
        }

        return sharesArr;
      }

      const purchaseOffersArr = allPurchaseOffers.filter(offer => offer.sharesSerieId === serie?.sharesSerieId && offer.active === true)

      return {
        companyName: company?.companyName,
        serieName: serie?.sharesSerieName,
        activeShares: getSharesArr(activeSharesArr),
        inactiveShares: getSharesArr(inactiveSharesArr),
        purchaseOffers: purchaseOffersArr
      }
    }, [allPurchaseOffers, companies, serieId, series, shares]
  )

  const handleClickReply = (offer: PurchaseOffer) => {
    const activeSharesArr = shares?.filter(s => s.status === 'active' && s.sharesSerieId === serieId)

    if (offer.minSharesQuantity > activeSharesArr.length) {
      setErrorMessage('error.notEnoughShares')
    } else {
      setChosenPurchaseOffer(offer);
      setOpenModal(true);
    }
  }

  useEffect(() => {
    getCompanies()
      .then(res => {
        setCompanies(res.data.companies);
        setSeries(res.data.series);
      })
      .catch(err => {
        setErrorMessage('error.backend')
      })
  }, [setErrorMessage])

  useEffect(() => {
    getShareholderSerieShares(serieId, shareholderId)
      .then(res => {
        setShares(res.data);
      })
      .catch(err => {
        setErrorMessage('error.backend')
      })
  }, [serieId, setErrorMessage, shareholderId])

  useEffect(() => {
    getPurchaseOffers()
      .then(res => {
        setAllPurchaseOffers(res.data)
      })
      .catch(err => {
        setErrorMessage('error.backend')
      })
  }, [setErrorMessage])

  useEffect(() => {
    getShareholders()
      .then(res => {
        setShareholders(res.data)
      })
      .catch(err => {
        setErrorMessage('error.backend')
      })
  }, [setErrorMessage])

  useEffect(() => {
    if (companies.length > 0 && series.length > 0 && shares.length > 0) {
      setData(getData())
      setIsLoading(false)
    }
  }, [companies, allPurchaseOffers, series, shares, getData, shareholders])

  return (
    <PageTemplate>
      <Box sx={{ padding: "3rem 5rem 1rem", flexGrow: '1', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', overflow: 'auto' }}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h4" component="h4">{data?.companyName}, {t('serieShares:serie')} {data?.serieName}</Typography>
              <Typography variant="h4" component="h4">{shares.length}</Typography>
            </Box>
            <Box>
              <Typography variant="h5" sx={{ margin: "3rem 0 1rem" }}>{t('serieShares:activeShares')}</Typography>
              {
                data?.activeShares.map((item, i) => {
                  return (
                    <Box key={i} sx={{ display: "flex", alignItems: "baseline", justifyContent: "space-between" }}>
                      <Typography variant="body1">{t('serieShares:sharesNumbers')}: {item.shareNumberStart} - {item.shareNumberEnd}</Typography>
                      <Box sx={{ display: "flex", alignItems: "baseline" }}>
                        <Typography variant="body1">{item.shareNumberEnd - item.shareNumberStart + 1}</Typography>
                        <Button variant="outlined" sx={{ margin: "0 0 0 1rem" }}>{t('serieShares:blockBtn')}</Button>
                      </Box>
                    </Box>
                  )
                })
              }
            </Box>
            {data?.inactiveShares.length !== 0 &&
              <Box>
                <Typography variant="h5" sx={{ margin: "3rem 0 1rem" }}>{t('serieShares:blockedShares')}</Typography>
                {
                  data?.inactiveShares.map((item, i) => {
                    return (
                      <Box key={i} sx={{ display: "flex", alignItems: "baseline", justifyContent: "space-between" }}>
                        <Typography variant="body1">{t('serieShares:sharesNumbers')}: {item.shareNumberStart} - {item.shareNumberEnd}</Typography>
                        <Box sx={{ display: "flex", alignItems: "baseline" }}>
                          <Typography variant="body1">{item.shareNumberEnd - item.shareNumberStart + 1}</Typography>
                          <Button variant="outlined" sx={{ margin: "0 0 0 1rem" }}>{t('serieShares:blockBtn')}</Button>
                        </Box>
                      </Box>
                    )
                  })
                }
              </Box>
            }
            {data?.purchaseOffers.length !== 0 &&
              <>
                <Divider sx={{ margin: "3rem 2rem" }} />
                <Box>
                  <Typography variant="h4" component="h4">{t('serieShares:purchaseOffersTitle')}</Typography>
                  {data?.purchaseOffers.map((item, i) => {

                    const offerCreator = shareholders.find(shareholder => shareholder.shareholderId === item.buyerId)

                    return (
                      <Box key={i} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline', width: '100%', padding: "1rem 4rem", boxShadow: '0 0 10px 5px rgb(71, 122, 255)', margin: '2rem 0' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <Typography component="h6" variant="h6">{offerCreator?.firstName} {offerCreator?.lastName}</Typography>
                          <Typography component="h6" variant="h6">Min. {item.minSharesQuantity} {t('serieShares:shares')}, max. {item.maxSharesQuantity} {t('serieShares:shares')}, {item.isPriceVisibility ? `${item.sharePrice} ${t('serieShares:priceForShare')}` : t('serieShares:noVisibilityPrice')} </Typography>
                        </Box>
                        <Button onClick={() => handleClickReply(item)} variant="contained" sx={{ alignSelf: 'center' }}>{t('serieShares:replyBtn')}</Button>
                      </Box>
                    )
                  })}

                  {openModal && chosenPurchaseOffer !== undefined &&
                    <SaleModal
                      offer={chosenPurchaseOffer}
                      onSuccess={() => {
                        setOpenModal(false);
                        setSuccessMessage('success.addSaleOffer')
                      }}
                      onError={() => {
                        setErrorMessage("error.backend")
                      }}
                      onClose={() => {
                        setOpenModal(false)
                      }} />}

                </Box>
              </>
            }
          </>
        )}
      </Box>

      <SnackbarComponent message={message} onClose={clearMessage} />

    </PageTemplate>
  )
}

export default Shares;