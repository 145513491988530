import { LoadingButton } from "@mui/lab";
import { Button, DialogActions } from "@mui/material";
import Modal from "components/modals/Modal";
import { useTranslation } from "react-i18next";

type Props = {
  isSubmitBtnDisabled?: boolean;
  loading: boolean;
  header?: React.ReactNode;
  content: React.ReactNode;
  open?: boolean;
  submitBtnLabel: string;
  onSubmit: () => void;
  onClose: () => void;
}

const ActionModal: React.FC<Props> = ({ isSubmitBtnDisabled, loading, header, content, open, onSubmit, onClose, submitBtnLabel }) => {
  const { t } = useTranslation('addSaleModal');

  const renderFooter = () => {
    return (
      <DialogActions>
        <LoadingButton loading={loading} disabled={isSubmitBtnDisabled} onClick={onSubmit} data-testid="btn.submit">{submitBtnLabel}</LoadingButton>
        <Button onClick={onClose} data-testid="btn.close">{t('addSaleModal:cancelBtn')}</Button>
      </DialogActions>
    )
  }

  return <Modal
    header={header}
    content={content}
    footer={renderFooter()}
    open={open} />
}

export default ActionModal;