import { useState } from "react";
import { Table, TableRow, TableCell, TableBody, Pagination, TableContainer } from "@mui/material";
import TableHeader from "pages/shareholderShares/table/TableHeader";
import Loader from "components/loader/Loader";
import { sharesHead } from "const/sharesHead";
import { useNavigate } from "react-router";
import { TableDataObj } from "..";
import { useTranslation } from "react-i18next";

type Props = {
  data: Array<TableDataObj>;
  isLoading: boolean;
}

const TableComponent: React.FC<Props> = ({ data, isLoading }) => {
  const [page, setPage] = useState<number>(1);
  const rowsPerPage = 10;
  const navigate = useNavigate();
  const { t } = useTranslation('shareholderShares')

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <TableContainer sx={{ height: '100%', margin: '2rem 0 0' }}>
            <Table sx={{ width: '100%' }}>

              <TableHeader def={sharesHead} />
              <TableBody>

                {data
                  .slice(page * rowsPerPage - rowsPerPage, page * rowsPerPage)
                  .map((item, i) => {

                    return (
                      <TableRow
                        hover
                        key={i}
                        onClick={() => navigate(`/shares/${item.serie.sharesSerieId}`)}
                        sx={{ borderBottom: '2px solid rgb(20, 30, 56)', cursor: 'pointer' }}
                      >
                        <TableCell>{item.company?.companyName}, {t('shareholderShares:serie')} {item.serie.sharesSerieName}</TableCell>
                        <TableCell>{item.shares.length}</TableCell>
                      </TableRow>
                    )
                  })}

              </TableBody>

            </Table>
          </TableContainer>


          <Pagination
            count={Math.ceil(data.length / rowsPerPage)}
            page={page}
            onChange={(event, newPage) => setPage(newPage)}
          />
        </>
      )}
    </>

  )
}

export default TableComponent;
