import { useState } from "react";
import { Table, TableRow, TableCell, TableBody, Pagination, TableContainer, Button } from "@mui/material";
import TableHeader from "pages/sharesPurchaseBoard/table/TableHeader";
import Loader from "components/loader/Loader";
import SnackbarComponent from "components/snackbar/Snackbar";
import { useMessage } from "components/snackbar/useMessage";
import { SharesPurchaseBoardHead } from "const/sharesPurchaseHead";
import { TableDataObj } from "pages/sharesPurchaseBoard/index";
import PurchaseModal from "components/add-purchase-modal";
import { Serie } from "services/shareholder";
import { useTranslation } from "react-i18next";

type Props = {
  data: Array<TableDataObj>
  onAddPurchaseOffer: () => void;
  isLoading: boolean;
}

const TableComponent: React.FC<Props> = ({ data, isLoading, onAddPurchaseOffer }) => {
  const [page, setPage] = useState<number>(1);
  const [companySeries, setCompanySeries] = useState<Array<Serie>>([])
  const rowsPerPage = 10;
  const [openModal, setOpenModal] = useState<boolean>(false)
  const { message, clearMessage, setErrorMessage, setSuccessMessage } = useMessage();
  const { t } = useTranslation('sharesPurchaseBoard')

  const handleClickOfferButton = (series: Serie[]) => {
    setCompanySeries(series);
    setOpenModal(true);
  }

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <TableContainer sx={{ height: '100%', margin: '2rem 0 0' }}>
          <Table sx={{ width: '100%' }}>

            <TableHeader def={SharesPurchaseBoardHead} />
            <TableBody>

              {data
                .slice(page * rowsPerPage - rowsPerPage, page * rowsPerPage)
                .map((item, i) => {

                  return (
                    <TableRow
                      hover
                      key={i}
                      sx={{ borderBottom: '2px solid rgb(20, 30, 56)' }}
                    >
                      <TableCell>{item.company?.companyName}</TableCell>
                      <TableCell>{item.purchaseOffers.length}</TableCell>
                      <TableCell align="right">
                        <Button onClick={() => handleClickOfferButton(item.series)}>{t('sharesPurchaseBoard:makeOfferBtn')}</Button>
                      </TableCell>
                    </TableRow>
                  )
                })}

            </TableBody>

          </Table>
        </TableContainer>

      )}

      <Pagination
        count={Math.ceil(data.length / rowsPerPage)}
        page={page}
        onChange={(event, newPage) => setPage(newPage)}
      />

      {openModal && <PurchaseModal
        series={companySeries}
        onSuccess={() => {
          setOpenModal(false);
          setSuccessMessage('success.addPurchaseOffer')
          onAddPurchaseOffer();
        }}
        onError={(errorMessage: string) => {
          setErrorMessage(errorMessage)
        }}
        onClose={() => {
          setOpenModal(false)
        }}
      />}

      <SnackbarComponent message={message} onClose={clearMessage} />

    </>

  )
}

export default TableComponent;
